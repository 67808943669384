.accommodation-title {
    .is-h1 {
        @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 36px;
        }

        + .review-rating {
            margin-top: -$default-margin;
        }
    }
}

.accommodation-gallery {
    overflow: hidden;
    margin: 0 -10px 10px;

    &__item {
        float: left;
        width: 25%;
        padding: 0 10px;
        margin: 0 0 20px;
        position: relative;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &:first-child {
            width: 50%;

            @include media-breakpoint-down(md) {
                float: none;
                clear: both;
                display: block;
                width: 100%;
            }
        }

        a {
            position: relative;
            display: block;

            .label {
                position: absolute;
                top: 15px;
                left: 15px;

                &--overlay {
                    background: $color-white;
                    padding: 0 15px;
                    border-radius: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 11px;
                    color: $text-color;
                }
            }
        }
    }

    .gallery-view-all {
        float: right;

        @include media-breakpoint-down(sm) {
            margin-right: 10px;
        }
    }
}

.accommodation-video {
    display: flex;
    justify-content: flex-end;
    margin: -10px 0 20px;
}

.accommodation-columns {
    $self: &;

    @include make-row;
    margin: 0 -10px;

    &__title {
        @include make-col(12);
        padding: 0 10px;
    }

    &__item {
        @include make-col(6);
        position: relative;
        padding: 0 10px 1px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        &--large {
            @include make-col(8);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        &--small {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        &::after {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 0;
            display: block;
            height: 1px;
            content: '';
            background-color: $color-gray;
        }

        .columns {
            column-gap: 20px;

            &--2 {
                column-count: 2;

                @include media-breakpoint-down(md) {
                    column-count: 1;
                }
            }
        }

        .maps-small {
            height: calc(100% - 30px);
            min-height: 300px;
        }

        .table {
            &--accommodation {
                .chat {
                    display: none;
                }
            }
        }
    }

    &--large {
        #{ $self }__item {
            @include make-col(12);
        }
    }
}

.accommodations-overview {
    @include make-row;
    margin: 0 -10px;

    &__item {
        @include make-col(6);
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.review {
    $self: &;
    display: flex;
    flex-direction: column;

    &__content {
        position: relative;
        background-color: $color-white;
        box-shadow: 0 0 20px rgba($color-black, .10);
        border-radius: 4px;
        padding: 15px 15px 0;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 18px 28px 18px 0;
            border-color: transparent $color-white transparent transparent;
            filter: drop-shadow(0 0 20px rgba($color-black, .10));
            position: absolute;
            right: 0;
            bottom: -18px;
        }
    }

    &__title {
        font-size: 12px;
        line-height: 26px;
        text-align: right;
        padding: 0 30px 0 0;
    }
}

.review-rating {
    color: $color-blue;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: $default-margin / 2;

    .emphasize {
        margin: 0 5px;
    }
}

.reviews {
    display: flex;
    flex-direction: column;

    &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .list {
            margin-left: auto;
        }
    }

    &__content {
        .content-teaser {
            margin: 0 -10px 30px;

            button {
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            .review {
                margin: 10px 10px 20px;
            }
        }
    }
}

// Custom breakpoints for mobile for the zoekenboek calendar on accommodation pages
.zoekenboek-iframe {
    @media (width <= 767px) {
        height: 750px;
    }

    @media (width <= 500px) {
        height: 650px;
    }

    @media (width <= 350px) {
        height: 550px;
    }
}
