.breadcrumb {
    @include media-breakpoint-down(lg) {
        display: none;
    }

    &-list {
        display: flex;
        padding: 0;
        margin: 0;
        font-size: 12px;
    }

    &-item {
        color: $text-color;

        a {
            color: $text-color;
        }

        &.active {
            a {
                color: $text-color;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                &::before {
                    color: $text-color;
                    content: '>';
                }
            }
        }
    }
}
