// scss-lint:disable PropertySpelling

.travelbase-searchbox {
    .number-group {
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;

        input[type="number"] {
            text-align: center;
            border: 0;
            width: 40px;
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
                margin: 0;
            }

            &:focus {
                outline: none;
            }
        }

        &__btn {
            background-color: $color-white;
            color: $color-blue-light;
            width: 50px;
            height: 50px;
            font-weight: 800;
            text-align: center;
            outline: none;
            border-radius: 50%;
            line-height: 50px;
            font-size: 18px;
            font-family: Arial, Helvetica, sans-serif;
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: $color-blue;
                color: $color-white;
            }
        }
    }

    [data-searchbox-datepicker] {
        @include icon(agenda, $color-black-light, 16px, 16px);
        background-position: center left 10px;
        padding: 11px 0 11px 35px;
        color: $color-black-light;
        width: 100%;

        &:focus {
            @include icon(agenda, $color-black-light, 16px, 16px);
            background-position: center left 10px;
        }
    }

    [data-travelgroup-wrapper] {
        position: relative;

        @include media-breakpoint-down(md) {
            position: static;
        }
    }

    .btn-select-travelgroup {
        font-family: $text-font;
        color: $text-color;
        font-size: $font-size;
        line-height: $line-height;
        font-weight: $font-weight-medium;
        border: 1px solid $color-gray;
        background: transparent;
        border-radius: 30px;
        padding: 3px 30px 3px 17px;
        text-align: left;
        width: 100%;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0;
        white-space: wrap;

        @include media-breakpoint-up(md) {
            padding: 13px 30px 13px 18px;
            text-align: left;
        }

        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 16px;
            padding: 20px 30px 20px 17px;
        }

        &::after {
            @include icon(select, $text-color, 11px, 11px);
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 35px;
            height: 100%;

            @include media-breakpoint-up(md) {
                width: 43px;
            }
        }

        &.active {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    .travelgroup-options {
        display: none;
        position: absolute;
        z-index: 10;
        left: 0;
        background: $color-blue-light;
        width: 100%;
        top: 110%;
        padding: 10px 20px;
        box-shadow: 0 0 10px rgba($color-black, .2);
        border-radius: 5px;

        @include media-breakpoint-up(sm) {
            min-width: 295px;
        }

        @include media-breakpoint-down(md) {
            position: fixed;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100vh;
            width: 100vw;
            border-radius: 0;
            overflow: auto;
            padding-bottom: 70px;
        }

        @include media-breakpoint-down(sm) {
            height: calc(100vh + 68px);
        }

        &.active {
            display: block;
        }

        .travelgroup-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0 15px;

            label {
                color: $color-white;
                font-size: 14px;
                margin: 0 10px 0 0;
                line-height: normal;

                span {
                    font-size: 12px;
                    display: block;
                    font-weight: 400;
                    line-height: normal;
                    color: $color-white;
                }
            }

            input {
                width: 45px;
                padding: 0 4px;
                background-color: transparent;
                color: $color-white;
            }

            &:last-of-type {
                margin-bottom: 0;

                .btn {
                    &--transparent {
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: block;
                        }
                    }

                    + .btn {
                        margin-left: 0;

                        @include media-breakpoint-down(md) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .btn-toggle,
    .btn-submit {
        width: 100%;
        color: $color-blue-light;
        background-color: $color-white;
        padding: 12px 15px;

        @include media-breakpoint-down(md) {
            width: 87%;

            + .btn {
                margin-left: 0;
            }
        }

        &:hover,
        &:focus {
            background-color: $color-blue;
            color: $color-white;
        }
    }

    .btn-toggle {
        display: none;
        width: 10%;

        @include media-breakpoint-down(md) {
            display: inline-block;
        }

        span {
            display: block;
            transform: rotate(-90deg);
        }
    }
}

// scss-lint:enable PropertySpelling
