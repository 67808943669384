// Pagination block

.block {
    &--pagination {
        margin: 0 0 $default-block-margin;

        .pagination {
            margin: 0;
        }
    }
}
