// Events block

.block {
    &--events {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.events {
    @include make-row;
    margin: 0 -15px;

    &__title {
        @include make-col(3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 45px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
            padding: 0 15px;
        }

        .is-h2 {
            + .read-more {
                margin-top: -$default-margin;
            }
        }

        .read-more {
            font-weight: $font-weight-medium;
        }
    }

    &__content {
        @include make-col(9);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .articles-overview {
            &__item {
                @include make-col(4);

                @include media-breakpoint-down(md) {
                    @include make-col(6);
                }

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }
            }
        }
    }

    &__buttons {
        @include make-col(12);
        text-align: center;
    }
}
