// scss-lint:disable PropertySpelling

.modal {
    $self: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 60px 20px 40px;
    overflow: auto;
    visibility: hidden;
    background: transparentize($color-black, .6);
    z-index: -1;

    @include media-breakpoint-down(md) {
        height: calc(100vh - 68px);
    }

    &__content {
        position: relative;
        width: 60%;
        margin: 0 auto;
        padding: 30px 30px 0;
        color: $text-color;
        background: $color-white;
        box-shadow: 0 0 20px transparentize($color-black, .8);
        border-radius: 12px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        > [data-modal] {
            position: absolute;
            top: -38px;
            right: 0;
            color: $color-white;
            padding: 0 0 0 15px;
            border: 0;
            text-transform: lowercase;

            &::after {
                @include icon(close, $color-white, 10px, 10px);
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 10px;
                height: 10px;
                margin: -3px 0 0;
                content: '';
            }

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: underline;

                &::after {
                    @include icon(close, $color-white, 10px, 10px);
                }
            }
        }
    }
}

[data-modal-container] {
    .modal {
        visibility: visible;
        z-index: 999;
    }
}

html {
    &.modal-active {
        overflow: hidden;
    }
}

// scss-lint:enable PropertySpelling
