// Hero slider block

.block {
    &--hero-slider {
        margin: 0 0 $default-block-margin;

        + .block {
            &--breadcrumbs {
                margin-top: -($default-block-margin / 2);
            }
        }
    }
}

.hero-slider {
    &__item {
        .hero {
            align-items: flex-start;
            height: 660px;

            @include media-breakpoint-down(md) {
                height: 382px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 57%;

                @include media-breakpoint-down(md) {
                    height: 67%;
                }
            }
        }
    }

    &--white {
        .richtext {
            .is-h1 {
                text-shadow: 0 0 6px $color-black;
                color: $color-white;
            }
        }
    }

    &--blue {
        .richtext {
            .is-h1 {
                color: $color-blue;
            }
        }
    }

    &--blue-light {
        .richtext {
            .is-h1 {
                text-shadow: 0 0 6px $color-black;
                color: $color-blue-light;
            }
        }
    }

    &--green {
        .richtext {
            .is-h1 {
                color: $color-green;
            }
        }
    }

    &--yellow {
        .richtext {
            .is-h1 {
                text-shadow: 0 0 6px $color-black;
                color: $color-yellow;
            }
        }
    }

    &--purple {
        .richtext {
            .is-h1 {
                color: $color-purple;
            }
        }
    }

    &--brown {
        .richtext {
            .is-h1 {
                color: $color-brown;
            }
        }
    }
}

.hero-slider-form {
    $self: &;

    @extend .wrapper;
    position: absolute;
    top: 57%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    z-index: 1;

    @include media-breakpoint-down(md) {
        top: 67%;
    }

    &--fullwidth {
        position: relative;
        max-width: unset;
        box-shadow: 0 0 20px transparentize($color-black, .8);

        #{$self}__content {
            @extend .wrapper;
            box-shadow: none;
        }
    }

    &__content {
        top: -60px;
        padding: 20px 20px 10px;
        background-color: $color-white;
        border-radius: 50px;
        box-shadow: 0 0 20px transparentize($color-black, .8);
        transition: top .3s ease-in-out;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .form {
            margin-bottom: 0;

            &.form-inline {
                @include media-breakpoint-down(xl) {
                    justify-content: space-between;
                }

                > .form-group {
                    flex: 1;
                    width: auto;

                    @include media-breakpoint-down(lg) {
                        @include make-col(6);
                        max-width: 50%;
                    }

                    &:first-of-type {
                        max-width: 20%;

                        @include media-breakpoint-down(xl) {
                            max-width: 22%;
                        }

                        @include media-breakpoint-down(lg) {
                            max-width: 50%;
                        }
                    }

                    &:not(.form-group--large):nth-of-type(2) {
                        max-width: 18%;

                        @include media-breakpoint-down(lg) {
                            max-width: 50%;
                        }
                    }

                    &:last-of-type {
                        flex: initial;
                        width: auto;
                        max-width: 25%;

                        @include media-breakpoint-down(xl) {
                            max-width: 35%;
                            margin-left: auto;
                            margin-right: auto;
                        }

                        @include media-breakpoint-down(md) {
                            width: 100%;
                            flex: 0 0 100%;
                            max-width: 100%;
                        }

                        .form-control-wrapper {
                            @include media-breakpoint-down(xl) {
                                text-align: center;
                            }
                        }
                    }

                    &--large {
                        @include media-breakpoint-down(xl) {
                            flex: 0 0 100%;
                            max-width: 100%;
                            width: 100%;
                            text-align: center;
                            order: 99;
                            margin: 0;
                        }

                        @include media-breakpoint-down(md) {
                            order: unset;
                        }

                        + .form-group {
                            @include media-breakpoint-down(xl) {
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }

                    .btn {
                        &:not(.btn-select-travelgroup) {
                            width: 100%;
                            text-align: center;

                            @include media-breakpoint-between(xl, lg) {
                                font-size: 14px;
                                padding-left: 40px;
                            }

                            @include media-breakpoint-down(lg) {
                                text-align: center;
                            }
                        }

                        &--search {
                            @include media-breakpoint-down(xl) {
                                font-size: 16px;
                                padding-left: 36px;
                                width: auto;

                                &::after {
                                    left: 12px;
                                }
                            }

                            @include media-breakpoint-down(lg) {
                                font-size: 18px;
                                padding-left: 48px;

                                &::after {
                                    left: 18px;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-left: auto;

                        @include media-breakpoint-down(md) {
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }

    &__buttons {
        display: none;
        text-align: center;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &.fixed {
        #{$self }__content {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 10px 10px 0;
            border-radius: 0;

            .form {
                @extend .wrapper;
                display: none;
                margin: 0 auto;
                padding: 0;

                .form-control {
                    padding-top: 3px;
                    padding-bottom: 3px;
                }

                .btn {
                    &--search {
                        width: 40px;
                        padding: 5px;
                        font-size: 0;

                        &::after {
                            left: 10px;
                        }
                    }
                }
            }
        }
    }
}

.swiper {
    &--hero-slider {
        overflow: visible;
    }
}
