// Overview block

.block {
    &--overview {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.overview {
    @include make-row;
    margin: 0 -15px;

    &__sidebar {
        @include make-col(3);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(9);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .articles-overview {
            &__item {
                @include make-col(4);

                @include media-breakpoint-down(md) {
                    @include make-col(6);
                }

                @include media-breakpoint-down(sm) {
                    @include make-col(12);
                }

                .article {
                    img {
                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .maps-filter {
            min-height: 750px;
        }

        .switch-view {
            @include media-breakpoint-down(sm) {
                margin-top: -100px;
            }
        }
    }

    &__buttons {
        @include make-col(12);
        text-align: center;
    }
}

.filter {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    &__content {
        @include media-breakpoint-down(md) {
            display: none;
        }

        .btn {
            width: 100%;
        }
    }

    &__button {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &.active {
        @include media-breakpoint-down(md) {
            position: fixed;
            inset: 0 0 68px;
            overflow: scroll;
            padding: 70px 30px 0;
            margin: 0;
            background-color: $color-white;
            z-index: 9999;
        }

        #{ $self }__button {
            .btn {
                &--filter {
                    position: fixed;
                    top: 15px;
                    right: 30px;
                    display: block;
                    width: 30px;
                    height: 30px;
                    font-size: 0;
                    border-color: $color-blue-light;
                    background-color: transparent;
                    border-radius: 50%;
                    padding: 0;
                    z-index: 1;

                    &::after {
                        @include icon(close, $color-blue-light, 14px, 14px);
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }

                    &:hover,
                    &:focus {
                        border-color: darken($color-blue-light, 10%);

                        &::after {
                            @include icon(close, darken($color-blue-light, 10%), 14px, 14px);
                        }
                    }
                }
            }
        }

        #{ $self }__content {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }
}
