@import '../../../../../../node_modules/leaflet/dist/leaflet';

// scss-lint:disable ImportantRule
.maps {
    position: relative;
    display: block;
    width: 100%;
    height: 300px;
    border-radius: 6px;
    overflow: hidden;
    margin: 0 0 $default-margin;

    &__stage {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__view-controls {
        position: absolute;
        top: 15px;
        left: 15px;
        display: flex;
        flex-direction: row;
        z-index: 10;

        label {
            display: block;
            color: $text-color;
            font-weight: 600;
            text-decoration: none;
            padding: 12px 15px;
            margin: 0;
            transition: all .3s;
            background-color: $color-white;
            border-bottom: 2px solid $color-gray;
            cursor: pointer;

            &:hover,
            &:focus {
                color: $text-color;
                text-decoration: none;
                border-bottom-color: $text-color;
            }
        }

        input[type="radio"] {
            display: none;

            &:checked {
                + label {
                    border-bottom-color: $text-color;
                }
            }
        }
    }

    &__zoom-controls {
        position: absolute;
        bottom: 15px;
        right: 15px;
        border: 2px solid $color-gray;
        border-radius: 4px;
        overflow: hidden;
        z-index: 10;

        button {
            display: block;
            width: 32px;
            height: 32px;
            font-size: 0;
            border: 0;
            padding: 0;
            background: $color-white;

            + button {
                border-top: 1px solid $color-gray;
            }

            &.zoom-in {
                background: $color-white url('/assets/img/maps/zoomin.svg') no-repeat center center;
                background-size: 11px 11px;
            }

            &.zoom-out {
                background: $color-white url('/assets/img/maps/zoomout.svg') no-repeat center center;
                background-size: 11px 11px;
            }
        }
    }

    &__route-control {
        position: absolute;
        left: 15px;
        bottom: 15px;
        display: block;
        color: $text-color;
        font-weight: 600;
        text-decoration: none;
        padding: 12px 15px;
        background-color: $color-white;
        border-bottom: 2px solid $color-gray;
        z-index: 10;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;
            border-bottom-color: $text-color;
        }
    }

    .leaflet-popup-content {
        margin: -1px !important;
    }

    .leaflet-popup-content-wrapper {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

.maps-popup {
    display: flex;
    flex-direction: column;
    width: 275px;
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $font-size;
    line-height: $line-height;
    padding: 10px 15px 0;
    border-radius: 5px;
    background-color: $color-white;

    p,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 10px;
    }

    .btn {
        &--link {
            color: $color-orange;

            &:hover,
            &:focus {
                color: $color-blue;
            }
        }
    }
}

// scss-lint:enable ImportantRule
