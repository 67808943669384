// Social Wall block

.block {
    &--social-wall {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.social-wall {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__content {
        margin-left: -5px;
        margin-right: -5px;
    }

    &__buttons {
        text-align: center;
    }
}

// scss-lint:disable ImportantRule
.flowbox-embed {
    .bzfy-o-container {
        margin: 0 0 $default-margin;

        .bzfy-c-tilegrid-post {
            overflow: hidden;
            border-radius: 6px;

            &__overlay {
                transform: translateY(-45px);
            }

            img {
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }

    .bzfy-t-feed-tile-grid {
        .bzfy-c-tilegrid-bottom-bar {
            padding: 0;
            margin: 0 0 $default-margin;

            .bzfy-feed-load-more-button {
                @extend .btn;
                text-transform: none;
                border-radius: 30px !important;
            }
        }
    }

    .bzfy-feed-load-more-button {
        background: $color-blue-light !important;
        font-size: 18px;
        line-height: 26px;
        text-transform: none;

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;
            border-color: darken($color-blue-light, 10%);
            background-color: darken($color-blue-light, 10%) !important;
        }
    }
}

// scss-lint:enable ImportantRule
