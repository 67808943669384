// Articles block

.block {
    &--articles {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.articles {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }

    &__title {
        h1 {
            font-size: 43px!important;
        }

        h2 {
            font-size: 36px!important;
        }

        h3 {
            font-size: 32px!important;
        }

        h4 {
            font-size: 26px!important;
        }
        
        h5 {
            font-size: 20px!important;
        }
    }
}

.article {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;

    &__image {
        border-radius: 6px;
        overflow: hidden;
        margin: 0 0 $default-margin;

        &[href] {
            picture {
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                picture {
                    transform: scale(1.2);
                }
            }
        }

        + #{ $self }__content {
            margin-top: -($default-margin / 1.5);
        }
    }

    &__content {
        .is-h4 {
            + .richtext,
            + .read-more {
                margin-top: -($default-margin / 1.5);
            }
        }

        .richtext {
            + .read-more {
                margin-top: -($default-margin / 1.5);
            }
        }

        .read-more {
            a {
                color: $color-blue;
                font-weight: $font-weight-medium;

                &::after {
                    @include icon(arrow, $color-blue, 11px, 11px);
                    display: inline-block;
                    width: 11px;
                    height: 11px;
                    content: '';
                    transform: rotate(-90deg);
                    margin: 0 0 0 7px;
                }
            }
        }
    }
}

.articles-overview {
    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(3);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--three {
        @include make-row;
        margin: 0 -15px;

        .articles-overview__item {
            @include make-col(4);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                width: 100%;
            }
        }
    }

    &--two {
        @include make-row;
        margin: 0 -15px;

        .articles-overview__item {
            width: 50%;
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            img {
                width: 100%!important;
            }

            img[loading] {
                width: 100%;
                height: 360px;
            }
        }
    }

    &--four {
        @include make-row;
        margin: 0 -15px;

        .articles-overview__item {
            @include make-col(3);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                width: 100%;
            }
        }
    }
}
