// Newsletter block

.block {
    &--newsletter {
        margin: 0;
    }
}

.newsletter {
    $self: &;

    @include make-row;
    padding: 100px 75px (100px - $default-margin);
    background-color: transparentize($color-blue-light, .9);
    border-radius: 6px;
    margin: 0 0 $default-block-margin;

    @include media-breakpoint-down(lg) {
        padding: $default-margin 15px 0;
    }

    @include media-breakpoint-down(md) {
        padding: $default-margin 0 0;
    }

    &__title {
        @include make-col(8);
        padding: 0 15px;
        margin: 0 auto 0 0;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }
    }

    &__content {
        @include make-col(4);
        padding: 0 15px;
        margin: 0 0 0 auto;

        @include media-breakpoint-down(lg) {
            @include make-col(12);
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }

        .is-h4 {
            + .activecampaign-form {
                margin-top: -25px;
            }
        }

        .form {
            .form-control {
                border-color: $color-white;
            }

            .btn {
                width: 100%;
            }
        }
    }

    &--reversed {
        #{ $self }__title {
            margin: 0 0 0 auto;
            order: 2;
        }

        #{ $self }__content {
            margin: 0 auto 0 0;
            order: 1;
        }
    }

    .activecampaign-form {
        padding: 0;
        background: transparent;
        border-radius: 0;

        // scss-lint:disable SelectorFormat

        // scss-lint:disable ImportantRule
        > div {
            width: 100%;
        }

        ._form {
            ._form-label {
                display: none !important;
            }
        }

        // scss-lint:enable SelectorFormat

        // scss-lint:enable ImportantRule
    }
}
