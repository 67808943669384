// Highlights block

.block {
    &--highlight,
    &--highlights {
        margin: 0;
        margin-bottom: $default-block-margin;
    }

    &--column-images {
        .highlights-overview {
            &__item {
                @include media-breakpoint-down(lg) {
                    @include make-col(12);
                }
            }
        }
    }
}

// scss-lint:disable DuplicateProperty
.highlight {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0 (50px - $default-margin);
    margin: 0;// 0 $default-block-margin;
    min-height: 480px;
    overflow: hidden;
    border-radius: 6px;

    @include media-breakpoint-down(md) {
        padding: $default-margin 0 0;
        min-height: 290px;
    }

    @include media-breakpoint-down(sm) {
        min-height: initial;
    }

    &--quicklinks {
        flex-direction: column;
        border-radius: 0;
        box-shadow: unset;
        overflow: unset;
        background: $color-white;
        margin-bottom: 160px;

        @include media-breakpoint-down(xs) {
            margin-left: -20px;
            margin-right: -20px;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 100px;
        }

        .list {
            margin-bottom: 0;

            li {
                ul {
                    li {
                        display: list-item;
                        list-style-type: disc;
                    }
                }

                ol {
                    li {
                        display: list-item;
                        list-style-type: decimal;
                    }
                }
            }
        }

        p {
            margin-bottom: 15px;
        }

        #{ $self }__image {
            @include make-col(12);
            margin-top: 0;
            border-radius: 5px;
            overflow: hidden;
            padding: 40px;

            img {
                border-radius: 6px;
            }

            + #{ $self }__content {
                @include make-col(8);
                margin-top: 270px;

                @include media-breakpoint-down(md) {
                    @include make-col(11);
                    margin-top: -200px;
                }

                @include media-breakpoint-down(sm) {
                    @include make-col(10);
                }
            }
        }

        #{ $self }__content {
            @include make-col(12);
            align-self: flex-end;
            border-radius: 5px;
            overflow: hidden;
            padding: 40px;
            background: $color-white;
            box-shadow: 0 0 20px rgba($color-black, .1);

            @include media-breakpoint-down(sm) {
                order: 2;
                margin-top: -107px;
            }

            h2,
            h3 {
                font-size: 23px;
                margin-bottom: 15px;
            }

            .list {
                h2,
                h3 {
                    margin-bottom: 0;
                }
            }

            a:not(.btn) {
                color: $color-blue-light;
                text-decoration: underline;

                &:hover {
                    color: darken($color-blue-light, 10%);
                }
            }
        }

        &#{ $self }--reversed {
            #{ $self }__image {
                @include make-col-pull(0);
                align-self: flex-start;
                order: 2;
                margin-top: 80px;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                    order: 1;
                }

                + #{ $self }__content {
                    margin-top: 350px;

                    @include media-breakpoint-down(sm) {
                        order: 2;
                        margin-top: -160px;
                    }
                }
            }

            #{ $self }__content {
                align-self: flex-end;
                order: 1;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            position: static;
            width: 100%;
            order: 1;
            margin-top: -200px;
        }

        picture {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__title,
    &__content,
    &__buttons {
        position: relative;
        z-index: 1;
        padding: 0 190px;

        @include media-breakpoint-down(xl) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }
    }

    &__title {
        .is-h1 {
            color: $color-white;
            text-shadow: 0 0 6px $color-black;
        }
    }

    &__content {
        .is-h4 {
            color: $color-white;
        }

        .richtext {
            * {
                color: $color-white;
            }
        }
    }

    &--static {
        .highlight {
            &__image {
                position: static;
                order: 0;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }
    }

    &--reversed {
        #{ $self }__image {
            right: auto;
            left: 0;

            picture {
                &::after {
                    left: auto;
                    right: -1px;
                    transform: rotate(-180deg);

                    @include media-breakpoint-down(sm) {
                        left: -1px;
                        right: -1px;
                        transform: none;
                    }
                }
            }
        }
    }

    &--small {
        justify-content: flex-start;
        min-height: 610px;
        padding: 30px 0 0;

        @include media-breakpoint-down(sm) {
            min-height: 480px;
            padding: $default-margin 0 0;
            border-radius: 0;
        }

        @include media-breakpoint-down(md) {
            min-height: 365px;
            padding: $default-margin 0 0;
        }

        #{ $self }__image {
            width: 100%;
        }

        #{ $self }__title,
        #{ $self }__content,
        #{ $self }__buttons {
            padding: 0 160px 0 30px;

            @include media-breakpoint-down(xl) {
                padding: 0 30px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 15px;
            }

            .btn {
                text-align: left;
            }
        }

        #{ $self }__title {
            .is-h1 {
                font-size: 42px;
                line-height: 52px;
                text-shadow: 0 0 6px $color-black;

                @include media-breakpoint-down(sm) {
                    font-size: 30px;
                    line-height: 36px;
                    word-break: break-word;
                }
            }
        }
    }

    &--blue-light {
        background-color: $color-blue-light;

        #{ $self }__image {
            picture {
                &::after {
                    background: $color-blue-light;
                    background: linear-gradient(90deg, $color-blue-light 0%, transparentize($color-blue-light, 1) 100%);

                    @include media-breakpoint-down(sm) {
                        background: linear-gradient(-180deg, $color-blue-light 0%, transparentize($color-blue-light, 1) 100%);
                    }
                }
            }
        }
    }

    &--green {
        background-color: $color-green;

        #{ $self }__image {
            picture {
                &::after {
                    background: $color-green;
                    background: linear-gradient(90deg, $color-green 0%, transparentize($color-green, 1) 100%);

                    @include media-breakpoint-down(sm) {
                        background: linear-gradient(-180deg, $color-green 0%, transparentize($color-green, 1) 100%);
                    }
                }
            }
        }
    }

    &--yellow {
        background-color: $color-yellow;

        #{ $self }__image {
            picture {
                &::after {
                    background: $color-yellow;
                    background: linear-gradient(90deg, $color-yellow 0%, transparentize($color-yellow, 1) 100%);

                    @include media-breakpoint-down(sm) {
                        background: linear-gradient(-180deg, $color-yellow 0%, transparentize($color-yellow, 1) 100%);
                    }
                }
            }
        }
    }

    &--purple {
        background-color: $color-purple;

        #{ $self }__image {
            picture {
                &::after {
                    background: $color-purple;
                    background: linear-gradient(90deg, $color-purple 0%, transparentize($color-purple, 1) 100%);

                    @include media-breakpoint-down(sm) {
                        background: linear-gradient(-180deg, $color-purple 0%, transparentize($color-purple, 1) 100%);
                    }
                }
            }
        }
    }

    &--brown {
        background-color: $color-brown;

        #{ $self }__image {
            picture {
                &::after {
                    background: $color-brown;
                    background: linear-gradient(90deg, $color-brown 0%, transparentize($color-brown, 1) 100%);

                    @include media-breakpoint-down(sm) {
                        background: linear-gradient(-180deg, $color-brown 0%, transparentize($color-brown, 1) 100%);
                    }
                }
            }
        }

        #{ $self }__title {
            .is-h1 {
                text-shadow: unset;
                color: $color-blue;
            }
        }

        #{ $self }__content {
            .richtext {
                * {
                    color: $color-blue;
                }
            }
        }
    }

    &--image-two-columns {
        background-color: transparent;

        #{ $self }__image {
            width: 100%;
            position: static;
            margin-bottom: $default-margin;
            border-radius: 6px;
            overflow: hidden;
            order: unset;
            margin-top: 0;

            picture {
                &::after {
                    display: none;
                }
            }
        }

        #{ $self }__content {
            padding: 0 20px;

            @include media-breakpoint-down(md) {
                padding: 0;
            }

            .is-h4 {
                font-size: 24px;
                line-height: normal;

                + .richtext {
                    margin-top: -$default-margin;
                }
            }

            .richtext {
                * {
                    color: $text-color;
                }

                + p {
                    margin-top: -($default-margin / 2);
                }
            }
        }

        &#{ $self }--white {
            #{ $self }__content {
                .is-h4 {
                    color: $text-color;
                }

                .btn {
                    background: $color-white;
                    border-color: $text-color;
                    color: $text-color;

                    &:hover,
                    &:focus {
                        color: $color-white;
                        border-color: $text-color;
                        background-color: $text-color;
                    }
                }
            }
        }

        &#{ $self }--blue {
            #{ $self }__content {
                .is-h4 {
                    color: $color-blue;
                }

                .btn {
                    background: $color-blue;
                    border-color: $color-blue;

                    &:hover,
                    &:focus {
                        border-color: darken($color-blue, 10%);
                        background-color: darken($color-blue, 10%);
                    }
                }
            }
        }

        &#{ $self }--blue-light {
            #{ $self }__content {
                .is-h4 {
                    color: $color-blue-light;
                }

                .btn {
                    background: $color-blue-light;
                    border-color: $color-blue-light;

                    &:hover,
                    &:focus {
                        border-color: darken($color-blue-light, 10%);
                        background-color: darken($color-blue-light, 10%);
                    }
                }
            }
        }

        &#{ $self }--green {
            #{ $self }__content {
                .is-h4 {
                    color: $color-green;
                }

                .btn {
                    background: $color-green;
                    border-color: $color-green;

                    &:hover,
                    &:focus {
                        border-color: darken($color-green, 10%);
                        background-color: darken($color-green, 10%);
                    }
                }
            }
        }

        &#{ $self }--yellow {
            #{ $self }__content {
                .is-h4 {
                    color: $color-yellow;
                }

                .btn {
                    background: $color-yellow;
                    border-color: $color-yellow;

                    &:hover,
                    &:focus {
                        border-color: darken($color-yellow, 10%);
                        background-color: darken($color-yellow, 10%);
                    }
                }
            }
        }

        &#{ $self }--purple {
            #{ $self }__content {
                .is-h4 {
                    color: $color-purple;
                }

                .btn {
                    background: $color-purple;
                    border-color: $color-purple;

                    &:hover,
                    &:focus {
                        border-color: darken($color-purple, 10%);
                        background-color: darken($color-purple, 10%);
                    }
                }
            }
        }

        &#{ $self }--brown {
            #{ $self }__content {
                .is-h4 {
                    color: $color-brown;
                }

                .btn {
                    background: $color-brown;
                    border-color: $color-brown;
                    color: $text-color;

                    &:hover,
                    &:focus {
                        border-color: darken($color-brown, 10%);
                        background-color: darken($color-brown, 10%);
                    }
                }
            }
        }
    }
}
// scss-lint:enable DuplicateProperty

.highlights {
    display: flex;
    flex-direction: column;

    .highlights-overview {
        &__item {
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }
    }
}

.highlights-overview {
    @include make-row();
    margin: 0 -15px;

    &__item {
        @include make-col(6);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        &:nth-of-type(2) {
            .highlight {
                &__content {

                    @include media-breakpoint-down(md) {
                        margin-top: 350px;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-top: -160px;
                    }
                }
            }
        }
    }

    .highlight {
        &__image {
            picture {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.highlights-new {
    .highlights {
        &__content {
            min-height: 200px;

            .highlight-simple {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 0 0 60px;
                min-height: 400px;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }

                &__content {
                    flex: 0 0 50%;
                    max-width: 50%;
                    position: relative;
                    align-self: self-end;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: $color-white;
                    word-break: break-word;
                    z-index: 1;
                    border: 0;
                    margin-left: -110px;
                    box-shadow: 0 0 13px 0 rgba(0, 0, 0, .46);
                    padding: 40px;
                    margin-top: 100px;

                    @include media-breakpoint-down(md) {
                        margin-top: 10px;
                        max-width: 100%;
                    }

                    .richtext {
                        margin: 0 0 30px;
                    }
                }

                &__image {
                    flex: 0 0 50%;
                    max-width: 50%;
                    box-shadow: 0 0 13px 0 rgba(0, 0, 0, .46);
                    border-radius: 5px;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }

                    picture {
                        display: block;

                        img {
                            border-radius: 5px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.reversed {
        .highlights {
            &__content {
                .highlight-simple {
                    flex-direction: row-reverse;
                    min-height: 400px;
    
                    &__content {
                        margin-right: -110px;
                    }
                }
            }
        }
    }
}


.highlights-simple {
    .highlights {
        &__content {
            min-height: 200px;

            .highlight-simple {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin: 0 0 60px;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }

                &__content {
                    flex: 0 0 50%;
                    max-width: 50%;
                    position: relative;
                    padding: 20px 20px 0;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: $color-white;
                    word-break: break-word;
                    z-index: 1;
                    margin-left: 0;
                    border: 0;
                    box-shadow: none;
                    align-self: normal;
                    margin-top: 20px;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }

                    .richtext {
                        margin: 0 0 30px;

                        p {
                            margin-bottom: 8px;
                        }
                    }
                }

                &__image {
                    flex: 0 0 50%;
                    max-width: 50%;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }

                    picture {
                        display: block;

                        img {
                            border-radius: 5px;
                            width: 100%;
                        }
                    }
                }

                &.reversed {
                    flex-direction: row-reverse;;
                }
            }
        }
    }
}
