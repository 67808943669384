button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    $self: &;
    position: relative;
    display: inline-block;
    font-family: $text-font;
    color: $color-white;
    font-size: 18px;
    line-height: 26px;
    padding: 15px 23px;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-align: center;
    border: 2px solid $color-blue-light;
    background-color: $color-blue-light;
    border-radius: 30px;

    + .btn {
        margin-left: 15px;
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        border-color: darken($color-blue-light, 10%);
        background-color: darken($color-blue-light, 10%);
    }

    &--secondary {
        background-color: $color-yellow;
        border: 2px solid $color-yellow;
        color: $color-black;

        &:hover,
        &:focus {
            color: $color-black;
            text-decoration: none;
            border-color: darken($color-yellow, 10%);
            background-color: darken($color-yellow, 10%);
        }
    }

    &--blue-light {
        color: $color-blue-light;
        text-decoration: none;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $color-blue-light;
        }
    }

    &--blue-dark {
        color: $color-blue;
        text-decoration: none;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $color-blue;
        }
    }

    &--small {
        padding: 5px 20px;
    }

    &--save {
        background: $color-white;
        color: $color-blue-light;
        text-align: center;
        margin-bottom: $default-margin / 2;
        font-size: 15px;
        padding: 10px 20px;

        &#{ $self }--transparent {
            border-color: $color-white;
            color: $color-white;
        }
    }

    &--white {
        color: $color-blue;
        border-color: $color-white;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $color-blue;
            border-color: darken($color-white, 10%);
            background-color: darken($color-white, 10%);
        }
    }

    &--transparent {
        color: $color-blue-light;
        background-color: transparent;

        &:hover,
        &:focus {
            color: darken($color-blue-light, 10%);
            border-color: darken($color-blue-light, 10%);
            background-color: transparent;
        }

        &#{ $self }--white {
            color: $color-white;
            border-color: $color-white;

            &:hover,
            &:focus {
                color: darken($color-white, 10%);
                border-color: darken($color-white, 10%);
            }
        }
    }

    &--link {
        color: $color-blue-light;
        font-size: $font-size;
        line-height: $line-height;
        padding: 0;
        border: 0;
        background-color: transparent;

        &:hover,
        &:focus {
            color: $text-color;
            background-color: transparent;
        }
    }

    &--search,
    &--filter {
        text-align: left;
        padding-left: 48px;

        @include media-breakpoint-between(xl, lg) {
            font-size: 16px;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 18px;
            display: block;
            width: 18px;
            height: 18px;
            margin: -9px 0 0;
            content: '';
        }
    }

    &--search {
        &::after {
            @include icon(search, $color-white, 18px, 18px);
        }
    }

    &--filter {
        &::after {
            @include icon(filter, $color-white, 18px, 18px);
        }
    }

    &--language {
        display: none;
    }

    &--icon {
        padding-left: 40px;

        &::after {
            position: absolute;
            top: 9px;
            left: 10px;
            display: block;
            width: 20px;
            height: 20px;
            content: '';
        }

        &.btn {
            &--big {
                padding-left: 48px;

                &::after {
                    top: 13px;
                    left: 13px;
                    width: 28px;
                    height: 28px;
                }
            }

            &--social-share {
                &::after {
                    @include icon(share, $color-white, 20px, 20px);
                }

                &.btn {
                    &--big {
                        &::after {
                            @include icon(share, $color-white, 28px, 28px);
                        }
                    }
                }
            }
        }
    }

    &--to-top {
        @include icon(arrow, $color-white, 20px, 20px);
        position: fixed;
        right: 50px;
        bottom: 50px;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 0;
        z-index: 9999;
        opacity: 0;
        transform: rotate(-180deg);
        border-radius: 50%;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &:hover,
        &:focus {
            background-color: darken($color-blue-light, 10%);
        }

        &.active {
            opacity: 1;
        }
    }

    &--whatsapp {
        background-color: $color-whatsapp;
        padding-left: 40px;

        &:hover,
        &:focus {
            background-color: darken($color-whatsapp, 10%);
        }

        &::after {
            @include icon(whatsapp, $color-white, 24px, 24px);
            content: '';
            height: 24px;
            left: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
        }
    }

    &--social-share {
        color: $text-color;
        font-size: 16px;
        line-height: 22px;
        font-weight: $font-weight-normal;
        padding: 5px 0 5px 36px;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;

        &:hover,
        &:focus {
            color: $color-pink;
            text-decoration: none;
            background-color: transparent;

            &::after {
                @include icon(share, $color-pink, 20px, 20px);
            }
        }

        &::after {
            @include icon(share, $text-color, 20px, 20px);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 32px;
            height: 32px;
            content: '';
        }
    }
}
