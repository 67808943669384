// Call to action block

.block {
    &--cta-block {
        display: flex;
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.cta-block {
    display: flex;
    flex-direction: column;

    &__content {
        text-align: center;

        .btn {
            margin: 12px;
        }
    }
}