// ActiveCampaign form block

// scss-lint:disable ImportantRule

// scss-lint:disable SelectorFormat

.block {
    &--ac-form {
        margin: 0;
    }
}

.ac-form {
    $self: &;

    @include make-row;
    padding: 100px 75px (100px - $default-margin);
    background-color: transparentize($color-blue-light, .9);
    border-radius: 6px;
    margin: 0 0 $default-block-margin;

    @include media-breakpoint-down(md) {
        padding: $default-margin 15px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: $default-margin 15px;
    }

    div:first-child {
        width: 100%;
    }

    &__title {
        @include make-col(12);
        padding: 0 15px;
    }

    &__content {
        @include make-col(12);
        padding: 0 15px;

        .form {
            .form-control {
                border-color: $color-white;
            }

            .btn {
                width: 100%;
            }
        }

        ._form {
            width: 100%;
            margin: 0 auto !important;
        }
    }

    &--right {
        #{ $self }__title {
            @include make-col(6);
            margin: 0 auto 0 0;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        #{ $self }__content {
            @include make-col(6);
            margin: 0 0 0 auto;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }
    }

    &--left {
        #{ $self }__title {
            @include make-col(6);
            margin: 0 0 0 auto;
            order: 2;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        #{ $self }__content {
            @include make-col(6);
            margin: 0 auto 0 0;
            order: 1;

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }
    }
}

.activecampaign-form {
    ._form {
        background: transparent !important;
        margin: 0 0 $default-margin !important;
        padding: 0 !important;
        width: 100% !important;

        ._form-content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        ._button-wrapper {
            margin: 0 !important;

            ._submit {
                width: 100% !important;
                font-weight: 700;
                padding: 12px 20px !important;
                font-size: 16px !important;
                border-radius: 30px !important;
                font-family: $text-font !important;
                background-color: $color-blue-light !important;
                transform: translateY(0);
                transition: transform .3s ease-in-out;

                &:hover,
                &:focus {
                    background-color: $color-blue !important;
                    transform: translateY(-5px);
                }
            }
        }

        ._error {
            position: relative !important;
            top: 0 !important;
            left: 0 !important;

            &._below {
                margin-top: 10px !important;
            }
        }

        ._form-label {
            font-weight: 700;
        }

        ._form_element {
            margin: 0 0 15px !important;

            ._field-wrapper {
                position: relative;

                textarea:not(.g-recaptcha-response) {
                    background: $color-white;
                    overflow: hidden;
                    border-radius: 30px !important;
                    padding: 12px 20px !important;
                    border: 0 solid !important;
                    min-height: 120px !important;
                }

                input {
                    &[type="text"],
                    &[type="email"],
                    &[type="phone"],
                    select {
                        background: $color-white;
                        overflow: hidden;
                        border-radius: 30px !important;
                        padding: 12px 20px !important;
                        border: 0 solid !important;
                    }
                }
            }

            input {
                width: 100%;
                border-radius: 30px;
                padding: 12px 20px;

                &[type="text"] {
                    width: 100% !important;
                    border: 0 !important;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

// scss-lint:enable SelectorFormat

