// Bootstrap settings
$container-max-widths: (
    'sm': 540px,
    'md': 720px,
    'lg': 960px,
    'xl': 1170px
) !default;

// Lightcase settings
$lightcase-custom: (
    'font-path': '/assets/fonts/',
    'breakpoint': 768px
) !default;

// Overall
$default-margin: 40px;
$default-block-margin: 60px;
$text-font: 'Montserrat', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$font-size: 16px;
$line-height: 32px;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$text-color: #012a56;
$color-white: #fff;
$color-black: #000;
$color-blue: #012a56;
$color-blue-bg: #93caed;
$color-blue-light: #008fdb;
$color-green: #219659;
$color-yellow: #e7b228;
$color-purple: #af7393;
$color-brown: #eeee9b;
$color-gray: #d1d1d1;
$color-orange: #fdae2a;
$color-pink: #ed1164;
$color-black-off: #333;
$color-black-light: #666;
$color-gray-1: #f5f7f8;
$color-gray-2: #eaeaea;
$color-gray-3: #f7f7f7;
$color-red: #db001b;
$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
