@import '../../../../../../node_modules/daterangepicker/daterangepicker';

// scss-lint:disable ImportantRule

// scss-lint:disable SelectorFormat

// scss-lint:disable PropertySpelling

.daterangepicker {
    font-family: $text-font;
    margin: 0;
    color: $color-white;
    padding: 10px 10px 0;
    background-color: $color-blue-light;
    border-radius: 6px;
    border: 0;

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0 !important;
        width: 100%;
        left: 0 !important;
        height: 100vh;
        justify-content: center;
        padding-bottom: 80px;
        overflow: auto;
    }

    &[style] {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-flow: row wrap;
            align-items: start;
            justify-content: center;
            gap: 20px;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-flow: column nowrap;
            gap: 0;
            align-items: stretch;
            justify-content: start;
        }

        > div {
            @include media-breakpoint-down(sm) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &::after,
    &::before {
        display: none;
    }

    &.auto-apply {
        .drp-selected {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .drp-buttons {
            width: 100%;
            border: 0;

            @include media-breakpoint-down(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            .applyBtn {
                @extend .btn--white;
                flex-grow: 1;
                max-width: 105px;
            }
        }
    }

    .drp-calendar {
        padding: 0;
        margin: 0 0 10px;

        &.left,
        &.right {
            padding: 0;
        }

        .calendar-table {
            border: 0;
            border-radius: 0;
            background: transparent;

            th,
            td {
                border: 0;
                border-radius: 0;
            }

            th {
                font-size: $font-size;
                line-height: $line-height;
                font-weight: $font-weight-normal;
            }

            td {
                border-radius: 50%;

                &.off {
                    opacity: .5;
                    color: $color-white;
                    background: transparent;
                }

                &.today {
                    font-weight: $font-weight-bold;
                }

                &.active {
                    background-color: darken($color-blue-light, 10%);
                }

                &.available {
                    &:hover,
                    &:focus {
                        background-color: darken($color-blue-light, 10%);
                    }
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

// scss-lint:enable SelectorFormat

// scss-lint:enable PropertySpelling
