h1,
.is-h1 {
    font-family: $heading-font;
    color: $color-blue;
    font-size: 64px;
    line-height: 74px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(md) {
        font-size: 46px;
        line-height: 56px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 35px !important;
        line-height: 42px;
        word-break: break-word;
    }
}

h2,
.is-h2 {
    font-family: $heading-font;
    color: $color-blue;
    font-size: 42px !important;
    line-height: 52px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(lg) {
        font-size: 30px !important;
        line-height: 36px;
        word-break: break-word;
    }
}

h3,
.is-h3 {
    font-family: $heading-font;
    color: $color-blue;
    font-size: 26px!important;
    line-height: 36px;
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(sm) {
        font-size: 24px !important;
        line-height: 28px;
    }
}

h4,
.is-h4 {
    font-family: $heading-font;
    color: $color-blue;
    font-size: 18px !important;
    line-height: 28px;
    font-weight: $font-weight-bold;
}

h5,
.is-h5,
h6,
.is-h6 {
    font-family: $heading-font;
    color: $color-blue;
    font-size: $font-size!important;
    line-height: $line-height;
    font-weight: $font-weight-bold;

    &:not(:last-child) {
        margin: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: 14px;
        margin-top: -$default-margin;
    }
}
