form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        appearance: none;
        user-select: none;

        &.form-control {
            &:not([size], [multiple]) {
                height: 57px;
            }
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;

    .form-message {
        padding: 10px 10px 0;
        color: $color-red;
        background: rgba($color-red, .1);
        border-left: 4px solid $color-red;
        margin: 0 0 10px;

        > * {
            margin-bottom: 10px;
        }

        a {
            color: $color-red;
            text-decoration: underline;
        }

        &--success {
            color: $color-green;
            background: rgba($color-green, .1);
            border-left: 4px solid $color-green;

            a {
                color: $color-green;
            }
        }
    }

    .form-group {
        margin: 0 0 10px;

        > * {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }

        > label {
            font-weight: $font-weight-bold;
        }

        &.has-error {
            .form-control {
                border-color: $color-red;
            }

            .help-block {
                color: $color-red;
            }
        }

        .form-floating {
            > label {
                font-size: $font-size;
                line-height: $line-height;
                padding: 13px 22px;
                margin: 0;
            }
        }
    }

    .form-floating,
    .form-control-wrapper {
        > * {
            margin: 0 0 10px;

            &:last-child {
                margin: 0;
            }
        }
    }

    // scss-lint:disable ImportantRule
    .form-control {
        font-family: $text-font;
        color: $text-color;
        font-size: $font-size;
        line-height: $line-height;
        font-weight: $font-weight-medium;
        height: auto !important;
        padding: 13px 18px;
        border: 1px solid $color-gray;
        background-color: $color-white;
        border-radius: 30px;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
            line-height: 16px;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 3px;
            padding-bottom: 3px;
        }

        &:focus {
            border-color: $color-blue-light;
            background-color: $color-white;
            box-shadow: none;
        }

        &--textarea {
            height: 120px;
        }

        &--select {
            @include icon(select, $text-color, 11px, 11px);
            background-position: center right 15px;
            background-color: $color-white;
            padding-right: 35px;
        }

        &--check {
            position: relative;
            padding: 0 0 0 28px;
            margin: 0 0 10px;
            border: 0;
            border-radius: 0;
            background-color: transparent;

            label {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                opacity: 0;
                position: absolute;

                + i {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $color-gray;
                    border-radius: 6px;

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: none;
                        width: 14px;
                        height: 14px;
                        content: '';
                        background-color: $color-blue-light;
                        border-radius: 6px;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }

                &:focus-visible {
                    + i {
                        outline: 1px solid $color-pink;
                    }
                }
            }

            input[type="radio"] {
                + i {
                    border-radius: 50%;

                    &::before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    // scss-lint:enable ImportantRule

    button {
        &.form-control {
            &:hover,
            &:focus,
            &:active {
                border-color: $color-gray;
            }
        }
    }

    &--travelgroup {
        .form-group {
            &:last-of-type {
                margin-bottom: $default-margin / 2;
            }
        }
    }
}

.form-horizontal {
    > .form-group {
        @include make-row;
        margin-left: 0;
        margin-right: 0;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-flow: row wrap;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;

    .form-message {
        flex: 1 100%;
        margin-left: 10px;
        margin-right: 10px;
    }

    > .form-group {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        width: 0;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            width: auto;
            max-width: 100%;
        }

        &:last-child {
            flex: 0;
            width: auto;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
            }
        }

        &--error {
            flex: 0 0 100%;
            text-align: center;

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }

        .form-control,
        .help-block {
            width: 100%;
        }

        .btn {
            white-space: nowrap;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        width: 100%;
        padding: 12px 55px 12px 15px;
        border-color: $color-gray;
        font-size: 18px;
        line-height: normal;
    }

    .btn {
        @include icon(search, $color-gray, 20px, 20px);
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 40px;
        height: 100%;
        padding: 0;
        font-size: 0;
        border-color: transparent;
        background-color: transparent;
        border-radius: 0;

        &:hover,
        &:focus {
            @include icon(search, $color-blue-light, 20px, 20px);
        }
    }
}

// scss-lint:disable ImportantRule
.hbspt-form {
    fieldset {
        padding: 0 !important;
        max-width: none !important;
    }

    .hs-main-font-element {
        display: none;
    }

    .hs-form-field {
        @extend .form-group;

        .input {
            margin: 0 !important;
        }
    }

    .form-columns-1 {
        .hs-input {
            width: 100% !important;
        }
    }

    .form-columns-2 {
        margin: 0 -10px;

        .form-group {
            width: 50%;
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .hs-error-msgs {
        .hs-error-msg {
            @extend .help-block;
            color: $color-red !important;
        }
    }

    .no-list,
    .inputs-list {
        margin: 0;
    }

    .hs-input {
        @extend .form-control;

        &.error {
            border-color: $color-red;
        }
    }

    .hs-button {
        @extend .btn;
    }

    .hs-form-booleancheckbox-display {
        &:last-of-type {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + span {
                position: relative;
                padding: 0 0 0 25px;
                margin: 0 0 10px;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: '';
                    border: 2px solid $color-pink;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: '';
                    background-color: $color-pink;
                }
            }

            &:checked {
                + span {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + span {
                &::after {
                    border-radius: 50%;
                }

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

::input-placeholder {
    color: $text-color;
    font-style: normal;
    opacity: 1;
}

::placeholder {
    color: $text-color;
    font-style: normal;
    opacity: 1;
}

:input-placeholder {
    color: $text-color;
    font-style: normal;
    opacity: 1;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}

.form-control-form {
    position: relative;
    z-index: 1;

    button:not(.btn) {
        text-align: left;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .form {
        position: absolute;
        top: 100%;
        width: 100%;
        display: none;
        min-width: 250px;
        color: $color-white;
        padding: 10px 10px 0;
        background-color: $color-blue-light;
        border-radius: 6px;

        .form-control {
            width: 75px;
            padding: 3px 12px;
            border-color: $color-white;
        }

        .form-group {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 0;

            > label {
                flex: 1;
                font-weight: $font-weight-normal;
                margin: 0;
            }
        }
    }

    &.active {
        .form {
            display: block;
        }
    }
}

.switch-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 0 $default-margin;

    @include media-breakpoint-down(md) {
        flex: 0 0 100%;
    }

    li {
        &:not(.active) {
            .btn {
                color: $color-blue-light;
                background-color: transparent;
            }
        }

        &:not(:first-child) {
            .btn {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:not(:last-child) {
            .btn {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
