.is-ios {
    .list {
        &--apps {
            li {
                display: none;

                &.app-store {
                    display: block;
                }
            }
        }
    }
}

.pwa-modal {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -10px;

    &__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid $color-blue;
        padding: 0 30px 20px;
        margin-bottom: $default-margin / 2;

        img {
            flex: 0 0 60px;
            max-width: 60px;
            height: 60px;
            margin-right: 30px;
        }

        .is-h3 {
            margin-bottom: 0;
        }
    }

    &__content {
        padding: 0 30px;

        p {
            margin-bottom: $default-margin / 2;
        }
    }

    .language {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 20px;

        &__item {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 5px;
            margin: 0 0 10px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 7px 15px 7px 55px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: $color-blue-light;
                line-height: 35px;
                border-bottom: 1px solid $color-white;
                font-weight: 600;
                border-radius: 30px;

                &::after {
                    position: absolute;
                    left: 20px;
                    display: block;
                    width: 16px;
                    height: 11px;
                    top: 52%;
                    transform: translateY(-50%);
                    content: '';
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                    background-color: $color-blue;
                }

                &.en {
                    &::after {
                        background: url('/assets/img/countries/gb.gif') no-repeat center center;
                    }
                }

                &.de {
                    &::after {
                        background: url('/assets/img/countries/de.gif') no-repeat center center;
                    }
                }

                &.nl {
                    &::after {
                        background: url('/assets/img/countries/nl.gif') no-repeat center center;
                    }
                }
            }
        }
    }
}

.mobile-install-pwa {
    display: none;
    padding: 10px 0;
    background: $color-gray-3;
    position: relative;

    @include media-breakpoint-down(xl) {
        z-index: 99999;
    }

    &.active {
        display: block;
    }

    .btn {
        padding: 10px 10px 10px 40px;
        margin: 0 auto;
        display: block;
        color: $color-blue;
        background: $color-white;
        border: 1px solid $color-gray;

        &::after {
            @include icon(app, $color-blue, 16px, 25px);
            position: absolute;
            top: 50%;
            left: 12px;
            display: block;
            width: 16px;
            height: 25px;
            transform: translateY(-50%);
            content: '';
        }

        &:hover,
        &:focus {
            color: $color-blue;
            text-decoration: none;

            &::after {
                @include icon(app, $color-blue, 16px, 25px);
            }
        }

        &--close {
            @include icon(close, $color-black-light, 16px, 16px);
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            display: block;
            width: 34px;
            height: 34px;
            font-size: 0;
            border: 0;
            border-radius: 50%;
            padding: 0;
        }
    }
}
