// Content block

.block {
    &--content-block {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.content-block {
    $self: &;

    @include make-row;
    width: calc(83.3333% + 30px);
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        width: auto;
        margin: 0 -15px;
        min-width: 100%;
    }

    &__title,
    &__content,
    &__buttons {
        @include make-col(12);
        padding: 0 15px;
    }

    &__buttons {
        text-align: center;
    }

    &--two-columns {
        #{ $self }__title,
        #{ $self }__content {
            @include make-col(6);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: $default-margin / 2;
            word-break: break-word;
            font-size: 28px;

            + p {
                margin-top: -($default-margin / 2);
            }
        }

        h4 {
            font-size: 26px;
        }

        h5 {
            font-size: 24px;
        }

        h6 {
            font-size: 22px;
        }
        

        .list {
            .btn {
                padding: 10px 20px;
                font-size: 16px;
            }
        }
    }

    .date {
        position: relative;
        top: -10px;
    }

    .list {
        &--buttons {
            text-align: left;
            display: flex;
            padding: 0;
            flex-flow: row wrap;
            grid-gap: 10px;

            .btn {
                margin-left: 0;
            }
        }
    }
}
