*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: $font-weight-normal;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    background: $color-white;
}

body {
    display: flex;
    flex-direction: column;
}

// scss-lint:disable ImportantRule
html {
    @include media-breakpoint-down(md) {
        padding: 0 0 68px;
    }

    &.primary-navigation-active {
        @include media-breakpoint-down(md) {
            // overflow: hidden;

            .btn {
                &--to-top {
                    display: none !important;
                }
            }
        }
    }

    &.primary-filter-active {
        @include media-breakpoint-down(md) {
            overflow: hidden;

            [data-animate] {
                > * {
                    transform: none !important;
                    transition: none !important;
                }
            }
        }
    }

    &.native-ios-active {
        .navigation {
            &--mobile {
                ul li a {
                    padding: 11px 15px;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

::selection {
    background-color: $color-gray-1;
}

.search-highlight {
    background-color: $color-gray-1;
}

.date {
    font-weight: 300;
    font-size: 15px;
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

a {
    &:focus-visible {
        outline: 1px solid $color-blue-light;
    }
}

li {
    &:focus-visible {
        outline: 1px solid $color-blue-light;
    }
}

a,
.link {
    color: $color-blue-light;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $text-color;
        outline: none;
        text-decoration: underline;
    }

    &:focus-visible {
        outline: 1px solid $color-blue-light;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.date-label {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    line-height: 14px;
    font-weight: $font-weight-bold;
    text-align: left;
    padding: 5px 10px;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

table {
    width: 100%;
    border-collapse: collapse;

    &.table-wrapper {
        margin: 0 0 $default-margin;
        overflow-x: scroll;

        table {
            margin: 0;

            tr {
                th,
                td {
                    @include media-breakpoint-down(md) {
                        min-width: 200px;
                    }
                }
            }
        }
    }
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    height: auto;
    max-width: 100%;

    &[loading] {
        width: auto;
    }
}

.location-image {
    &--hidden {
        display: none;
    }
}

.mobile-install-pwa {
    display: none;
    padding: 10px 0;
    background: $color-gray-3;
    position: relative;
}

.info {
    display: block;
    font-style: italic;
    margin: 0 0 $default-margin;
    color: $text-color;
}

.emphasize {
    color: $color-blue;
    font-weight: $font-weight-bold;
    font-style: normal;
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 25px 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.is-video {
    position: relative;

    &::after {
        @include icon(play, $color-white, 60px, 60px);
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        content: '';
    }

    &:hover,
    &:focus {
        &::after {
            @include icon(play, $color-blue-light, 60px, 60px);
        }
    }
}

.icon {
    display: inline-block;
    height: 40px;
    width: 40px;

    &--check {
        @include icon(check, $color-pink, 40px, 40px);
    }

    &--phone {
        @include icon(phone, $color-white, 14px, 14px);
    }

    &--instagram {
        @include icon(instagram, $color-white, 18px, 18px);
    }

    &--linkedin {
        @include icon(linkedin, $color-white, 18px, 18px);
    }

    &--email {
        @include icon(email, $color-white, 18px, 18px);
    }

    &--star {
        @include icon(star, $color-orange, 20px, 20px);
    }

    &--house {
        @include icon(house, $color-blue, 25px, 25px);
    }

    &--bathroom {
        @include icon(bathroom, $color-blue, 23px, 23px);
    }

    &--house-marker {
        @include icon(house-marker, $color-blue, 21px, 21px);
    }

    &--toilet {
        @include icon(toilet, $color-blue, 20px, 22px);
    }

    &--persons {
        @include icon(persons, $color-blue, 25px, 24px);
    }

    &--pets {
        @include icon(pets, $color-blue, 27px, 25px);
    }

    &--nopets {
        @include icon(nopets, $color-blue, 25px, 23px);
    }

    &--bedroom {
        @include icon(bedroom, $color-blue, 23px, 23px);
    }

    &--beds {
        @include icon(beds, $color-blue, 22px, 23px);
    }

    &--surface {
        @include icon(surface, $color-blue, 23px, 23px);
    }

    &--hashtag {
        @include icon(hashtag, $color-blue, 23px, 23px);
    }

    &--tree {
        @include icon(tree, $color-blue, 22px, 23px);
    }
}

picture,
figure {
    display: block;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
    padding-left: 15px;
    padding-right: 15px;
}

// scss-lint:disable IdSelector
#skiptocontent a {
    position: absolute;
    top: -45px;
    left: 0;
    padding: 6px;
    color: $color-white;
    background: $color-blue-light;
    border-bottom-right-radius: 8px;
    transition: top .1s ease-out;
    z-index: 1000;

    &:focus {
        top: 0;
        outline: none;
    }
}

// scss-lint:enable IdSelector
