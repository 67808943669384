// Anchor block

.block {
    &--anchor {
        position: sticky;
        top: 0;
        width: 100%;
        margin-bottom: $default-block-margin;
        z-index: 998;
        background-color: $color-white;
        border-top: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;
    }
}

.anchor {
    position: relative;
    display: flex;
    flex-direction: column;

    &__content {
        padding: 15px 15px 0;

        .navigation {
            &--anchor {
                margin: 0 0 15px;
            }
        }
    }
}
