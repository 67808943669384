// Categories block

.block {
    &--categories {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.categories {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }

    .is-h2 {
        @include media-breakpoint-down(lg) {
            font-size: 42px;
            line-height: 36px;
            word-break: break-word;
            text-align: center;
        }
    }
}

.category {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba($color-black, .10);

    &__image {
        margin: 0;
    }

    &__content {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 20px 10px 0;

        h2, h3, h4 {
            text-shadow: 0 0 6px #000 !important;
        }

        .is-h3 {
            color: $color-white;
            word-break: break-word;
            margin: 0 0 20px;
            font-size: 20px;
            line-height: 26px;
            text-shadow: 0 0 6px #000;
        }

        .richtext {
            color: $color-white;
        }
    }

    &[href] {
        #{ $self }__image {
            picture {
                transition: all .3s ease-in-out;
            }
        }

        &:hover,
        &:focus {
            #{ $self }__image {
                picture {
                    transform: scale(1.2);
                }
            }
        }
    }

    &--white {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-white;
            }

            .richtext {
                color: $color-white;
            }
        }
    }

    &--blue {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-blue;
            }

            .richtext {
                color: $color-blue;
            }
        }
    }

    &--blue-light {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-blue-light;
            }

            .richtext {
                color: $color-blue-light;
            }
        }
    }

    &--green {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-green;
            }

            .richtext {
                color: $color-green;
            }
        }
    }

    &--yellow {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-yellow;
            }

            .richtext {
                color: $color-yellow;
            }
        }
    }

    &--purple {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-purple;
            }

            .richtext {
                color: $color-purple;
            }
        }
    }

    &--brown {
        #{ $self }__content {
            .is-h3,.is-h4 {
                color: $color-brown;
            }

            .richtext {
                color: $color-brown;
            }
        }
    }
}

.categories-slider {
    $self: &;
    display: flex;
    flex-direction: row;
    margin: 0 0 $default-margin;

    + .swiper-navigation {
        margin-top: -($default-margin / 2);
    }

    &__item {
        @include make-col(2.4);
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(8.5);
        }

        .category {
            height: 100%;
        }
    }
}

.swiper {
    &--categories-slider {
        overflow: initial;
        margin: 0 -10px;

        .swiper-slide {
            height: auto;
        }

        .swiper-navigation {
            display: none;

            @include media-breakpoint-down(md) {
                display: flex;
            }
        }
    }
}
