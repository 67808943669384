.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;

        a {
            // display: block;
            color: $text-color;

            &.btn {
                text-decoration: none !important; // scss-lint:disable ImportantRule

                &--blue-light {
                    color: $color-blue-light !important; // scss-lint:disable ImportantRule
                }

                &--blue {
                    color: $color-blue !important; // scss-lint:disable ImportantRule
                }
            }
        }
    }

    &--tags {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px;

        li {
            margin: 0 10px 10px 0;

            &::before {
                display: none;
            }

            a {
                display: inline-block;
                color: $text-color;
                border: 2px solid $color-pink;
                font-size: 14px;
                line-height: 28px;
                padding: 0 14px;
                font-weight: $font-weight-normal;
                background-color: transparent;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: none;
                    background-color: $color-pink;
                }
            }
        }
    }

    &--table {
        display: table;
        width: 100%;

        li {
            display: table-row;

            span {
                display: table-cell;
                padding: 3px 0;

                &:first-of-type {
                    padding-right: 40px;
                }

                &:last-of-type {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &--footer {
        margin: 0 0 40px;

        li {
            display: block;
            word-break: break-word;

            a {
                display: block;
                color: $text-color;
                font-size: 14px;
                line-height: 36px;
                font-weight: $font-weight-medium;

                &:hover,
                &:focus {
                    color: $text-color;
                    text-decoration: underline;
                }
            }
        }
    }

    &--social-follow {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 20px;

        @include media-breakpoint-down(xl) {
            justify-content: center;
        }

        li {
            display: inline-block;
            margin: 0 5px 20px;

            a {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                font-size: 0;
                border-radius: 50%;
                background-color: $color-blue;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-facebook;
                    }

                    &::after {
                        @include icon(facebook, $color-gray-1, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }

                    &::after {
                        @include icon(twitter, $color-gray-1, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-youtube;
                    }

                    &::after {
                        @include icon(youtube, $color-gray-1, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-linkedin;
                    }

                    &::after {
                        @include icon(linkedin, $color-gray-1, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-instagram;
                    }

                    &::after {
                        @include icon(instagram, $color-gray-1, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &:hover,
                    &:focus {
                        background-color: $color-pinterest;
                    }

                    &::after {
                        @include icon(pinterest, $color-gray-1, 20px, 20px);
                    }
                }
            }
        }
    }

    &--social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0 0 8px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $text-color;
                font-size: 14px;
                line-height: 22px;
                padding: 5px 15px 5px 36px;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $text-color, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $text-color, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $text-color, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $text-color, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $text-color, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $text-color, 20px, 20px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $text-color, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $text-color, 20px, 20px);
                    }
                }
            }
        }
    }

    &--logos {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: 0 -15px ($default-margin - 15px);

        @include media-breakpoint-down(md) {
            display: none;
        }

        li {
            display: flex;
            align-items: center;
            padding: 0 15px;
            margin: 0 0 15px;

            @include media-breakpoint-down(lg) {
                width: 20%;
            }

            @include media-breakpoint-down(md) {
                width: auto;
            }

            a {
                display: block;

                img {
                    width: auto;
                    max-height: 56px;
                }
            }
        }
    }

    &--specs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 10px;
            margin: 0 0 $default-margin;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .emphasize {
                display: block;
            }
        }
    }

    &--reviews-summary {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $default-margin + 10px;

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            font-size: 14px;
            line-height: 26px;
            display: flex;
            flex-wrap: wrap;

            .emphasize {
                margin-left: auto;
                padding-right: 40px;
            }
        }
    }

    &--reviews-switch {
        display: flex;
        flex-wrap: wrap;

        li {
            position: relative;

            a {
                font-size: 14px;
                line-height: 26px;
                font-weight: 400;
                color: $color-gray;

                &.active {
                    font-weight: 700;
                    color: $color-blue;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                    color: $color-blue;
                }
            }

            + li {
                margin-left: 10px;

                &::before {
                    content: '|';
                    position: absolute;
                    left: -7px;
                    top: 0;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: 400;
                    color: $color-gray;
                }
            }
        }
    }

    &--attributes {
        display: flex;
        flex-wrap: wrap;

        li {
            @include make-col(6);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: normal;
            margin-bottom: $default-margin - 10px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            .icon {
                width: 25px;
                height: 25px;
                margin-right: 10px;
            }
        }
    }

    &--apps {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 0 ($default-margin - 10px);

        li {
            padding: 0 10px;
            margin: 0 0 10px;

            a {
                display: block;
                width: 128px;
                height: 40px;
                font-size: 0;
            }

            &.app-store {
                display: none;

                a {
                    background: url('/assets/img/apps/app-store.svg') no-repeat center center;
                }
            }

            &.google-play {
                a {
                    background: url('/assets/img/apps/google-play.svg') no-repeat center center;
                }
            }

            &.windows {
                display: none;

                a {
                    background: url('/assets/img/apps/windows.svg') no-repeat center center;
                }
            }
        }
    }
}
