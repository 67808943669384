.pagination {
    margin: 0 0 50px;
    border-radius: 0;

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin: 0;

        .page-item {
            display: inline-block;
            margin: 0 5px;

            &:first-child,
            &:last-child {
                .page-link {
                    border-radius: 50%;
                }
            }

            &.active {
                .page-link {
                    background-color: darken($color-blue-light, 10%);
                }
            }

            &.control {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                .page-link {
                    @include icon(arrow, $color-white, 17px, 17px);
                    background-color: darken($color-blue-light, 10%);
                    font-size: 0;
                }

                &.prev {
                    .page-link {
                        transform: rotate(90deg);
                    }
                }

                &.next {
                    .page-link {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .page-link {
            display: block;
            width: 60px;
            height: 60px;
            padding: 17px 0;
            color: $color-white;
            font-size: 18px;
            line-height: 26px;
            font-weight: $font-weight-bold;
            text-align: center;
            overflow: hidden;
            border-radius: 50%;
            background-color: $color-blue-light;
            border: 0;

            &:hover,
            &:focus {
                color: $color-white;
                text-decoration: none;
                background-color: darken($color-blue-light, 10%);
            }

            &:focus-visible {
                background-color: darken($color-blue-light, 10%);
            }
        }
    }
}
