.navigation {
    $self: &;

    ul {
        order: 2;
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $text-color;
                padding: 6px 5px;

                @include media-breakpoint-down(xl) {
                    padding: 6px 0;
                }
            }

            > ul {
                display: none;
            }


            &:hover {
                > a {
                    color: $color-blue-light;
                    text-decoration: none;
                }
            }

            &.active {
                > a {
                    color: $color-blue-light;
                }

                @include media-breakpoint-up(sm) {
                    > ul {
                        display: block;
                    }
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrow, $color-blue, 15px, 14px);
                    position: absolute;
                    top: 8px;
                    right: 0;
                    display: none;
                    width: 34px;
                    height: 34px;
                    padding: 0;
                    transform: rotate(0deg);
                    transition: all .3s;

                    @include media-breakpoint-down(xl) {
                        display: block;
                    }
                }

                &:hover {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-light, 15px, 14px);
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(180deg);
                    }

                    > ul {
                        @include media-breakpoint-down(xl) {
                            display: block;
                        }
                    }
                }

                // scss-lint:disable MergeableSelector
                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrow, $color-blue-light, 15px, 14px);
                        transform: rotate(0deg);
                    }

                    > ul {
                        @include media-breakpoint-down(xl) {
                            display: none;
                        }
                    }
                }

                // scss-lint:enable MergeableSelector
            }

            &.subnav-item-overview {
                display: none;

                @include media-breakpoint-down(xl) {
                    display: block;
                }
            }
        }
    }

    &--header {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0;

        @include media-breakpoint-down(xl) {
            top: 0;
            left: 100%;
            bottom: 68px;
            width: 100%;
            padding: 80px 0 15px;
            margin: 0;
            overflow: auto;
            background-color: $color-white;
            overflow-x: hidden;
            transition: all .3s ease-in-out;
            display: none;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin: 0;

            @include media-breakpoint-down(xl) {
                display: block;
                padding: 0 15px;
                text-align: left;
            }

            li {
                margin: 0;
                z-index: 0;

                @include media-breakpoint-down(xl) {
                    display: block;
                    padding: 5px;
                }

                a {
                    @include media-breakpoint-up(sm) {
                        font-size: 17px;
                    }
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: $font-weight-semibold;
                    padding: 5px 13px;
                    text-transform: none;
                    white-space: nowrap;

                    @include media-breakpoint-down(xl) {
                        font-weight: $font-weight-bold;
                    }
                }

                &:last-child {
                    > a {
                        @include media-breakpoint-down(sm) {
                            padding-right: 0;
                        }
                    }
                }

                > ul {
                    position: absolute;
                    left: -99999px;
                    display: flex;
                    background-color: $color-white;

                    @include media-breakpoint-down(sm) {
                        bottom: -10px;
                    }

                    @include media-breakpoint-down(xl) {
                        position: static;
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0 0 0 20px;
                        background-color: transparent;
                    }

                    li {
                        display: block;
                        margin: 0;

                        @include media-breakpoint-up(xl) {
                            padding-top: 5px;
                            padding-bottom: 5px;
                        }

                        a {
                            font-size: 16px;
                            font-weight: $font-weight-bold;
                            padding: 5px 10px;

                            @include media-breakpoint-up(sm) {
                                font-size: 15px;
                                font-weight: 500;
                                padding: 1px 12px;
                            }

                            @media only screen and (width >= 1200px) and (width <= 1501px) {
                                font-size: 15px;
                                padding: 5px;
                            }

                            @include media-breakpoint-down(xl) {
                                font-size: 14px;
                                font-weight: $font-weight-semibold;
                            }
                        }
                    }
                }

                .is-subnav {
                    visibility: hidden;
                    opacity: 0;
                }

                &:hover,
                &:focus-within {
                    z-index: 1;

                    > a {
                        color: $color-blue-light;
                    }

                    > ul {
                        left: auto;
                    }

                    .is-subnav {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                &.has-subnav {
                    @include media-breakpoint-down(sm) {
                        position: static;
                    }

                    @include media-breakpoint-up(sm) {
                        position: relative;
                    }

                    @include media-breakpoint-down(xl) {
                        position: relative;
                    }

                    > .toggle-subnav {
                        top: 5px;
                        right: 5px;
                    }

                    > ul {
                        left: -99999px;
                        right: auto;

                        @include media-breakpoint-down(xl) {
                            display: none;
                        }
                    }

                    &:hover,
                    &:focus-within {
                        > a {
                            position: relative;

                            &::before {
                                position: absolute;
                                top: 0;
                                display: block;
                                width: 100%;
                                height: 50px;
                                content: '';
                            }
                        }

                        .is-subnav {
                            visibility: visible;
                            opacity: 1;
                        }

                        &:last-child,
                        &:nth-last-child(2) {
                            ul {
                                @include media-breakpoint-up(sm) {
                                    left: auto;
                                    right: 0;
                                }
                            }
                        }

                        > ul {
                            left: 0;

                            @include media-breakpoint-down(sm) {
                                right: 0;
                            }

                            @include media-breakpoint-up(sm) {
                                display: flex;
                                position: absolute;
                                top: 100%;
                                background-color: $color-white;
                                flex-flow: column wrap;
                                z-index: 1000000;
                                box-shadow: 0 0 7px 1px rgb(0 0 0 / .20);

                                @include media-breakpoint-up(sm) {
                                    padding: 5px;
                                    border-radius: 5px;
                                }
                            }

                            @include media-breakpoint-down(xl) {
                                display: none;
                            }
                        }
                    }

                    &.active,
                    &.subnav-active {
                        > ul {
                            @include media-breakpoint-down(sm) {
                                display: flex;
                                left: 0;
                                right: 0;
                            }

                            @include media-breakpoint-down(xl) {
                                display: block;
                            }
                        }
                    }

                    &.active.subnav-active {
                        > ul {
                            left: -99999px;
                            right: auto;

                            @include media-breakpoint-down(xl) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        > ul {
            @include media-breakpoint-up(sm) {
                padding: 0 0 10px;
            }

            @include media-breakpoint-down(xl) {
                padding: 0;
            }

            > li {
                &.has-subnav {
                    > a {
                        @include media-breakpoint-down(xl) {
                            padding-right: 34px;
                        }
                    }

                    > .toggle-subnav {
                        display: none;

                        @include media-breakpoint-down(xl) {
                            display: block;
                        }
                    }
                }

                &.active {
                    > a {
                        position: relative;

                        &::after {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            width: 33%;
                            height: 2px;
                            margin: -4px auto 0;
                            content: '';
                            background-color: $color-blue-light;

                            @include media-breakpoint-down(xl) {
                                display: none;
                            }
                        }
                    }
                }
            }

            // scss-lint:disable ImportantRule
            ul ul {
                @include media-breakpoint-up(xl) {
                    display: none !important;
                }
            }

            // scss-lint:enable ImportantRule
        }

        .language {
            order: 1;
            padding: 0;

            @include media-breakpoint-down(xl) {
                align-self: baseline;
                margin-left: auto;
                margin-right: 50px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                border: 1px solid $color-blue;
                border-radius: 30px;
            }

            li {
                @include media-breakpoint-down(xl) {
                    padding: 0;
                }

                &:first-of-type {
                    @include media-breakpoint-down(xl) {
                        border-radius: 30px 0 0 30px;
                        overflow: hidden;
                    }
                }

                &:last-of-type {
                    @include media-breakpoint-down(xl) {
                        border-radius: 0 30px 30px 0;
                        overflow: hidden;
                    }
                }

                a {
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    padding: 0 3px;

                    @include media-breakpoint-down(xl) {
                        padding: 8px 15px;
                    }
                }

                &.active {
                    a {
                        @include media-breakpoint-down(xl) {
                            background-color: $color-blue;
                            color: $color-white;
                        }

                        &::after {
                            display: none;
                        }
                    }
                }

                &:last-child {
                    > a {
                        @include media-breakpoint-down(xl) {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }


    }

    &--footer {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                margin: 0 3px;

                a {
                    color: $text-color;
                    text-decoration: none;
                    text-transform: lowercase;
                    padding: 0;

                    &:hover,
                    &:focus {
                        color: $text-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--mobile {
        position: fixed;
        bottom: 0;
        display: none;
        width: 100%;
        background-color: $color-white;
        box-shadow: 0 0 20px transparentize($color-black, .8);
        z-index: 9999;

        @include media-breakpoint-down(xl) {
            display: block;
        }

        ul {
            display: flex;
            flex-flow: row wrap;

            li {
                flex: 1;

                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 11px 15px 5px;
                    font-size: 10px;
                    line-height: 26px;
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;

                    .icon {
                        position: relative;
                        display: block;
                        width: 46px;
                        height: 26px;
                        border-radius: 50%;

                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: block;
                            width: 38px;
                            height: 38px;
                            margin: -19px 0 0 -19px;
                            content: '';
                        }

                        &.timer {
                            &::after {
                                @include icon(timer, $color-blue-light, 26px, 26px);
                            }
                        }

                        &.search {
                            &::after {
                                @include icon(search, $color-blue-light, 18px, 18px);
                            }
                        }

                        &.blocks {
                            &::after {
                                @include icon(blocks, $color-blue-light, 38px, 38px);
                            }
                        }
                    }
                }

                &.active {
                    a {
                        color: $color-blue;

                        .icon {
                            height: 46px;
                            margin-top: -20px;
                            background-color: $color-blue-light;

                            &::after {
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                }
            }
        }
    }

    &--anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: $text-color;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: 400;
                    text-transform: none;
                    text-decoration: none;
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-blue;
                        text-decoration: none;
                    }
                }

                &.active {
                    > a {
                        color: $color-blue;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(xl) {
                left: 0;
                display: flex;
            }

            @include media-breakpoint-down(md) {
                padding-top: 25px;
            }
        }
    }
}

.header {
    &__content {
        .navigation {
            .primary {
                li {
                    ul {
                        li {
                            a {
                                @include media-breakpoint-up(md) {
                                    font-weight: 500;
                                }
                            }

                            ul {
                                @include media-breakpoint-up(md) {
                                    display: block!important;
                                    position: relative;
                                    left: unset;
                                    right: auto;
                                    padding: 5px 5px 5px 11px;
                                    margin-bottom: -10px;
                                }
                                
                                a {
                                    @include media-breakpoint-up(md) {
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

ul.primary > li.has-subnav > ul > li.has-subnav {
    ul {
        display: block!important;
        position: relative;
        box-shadow: unset;

        @include media-breakpoint-down(md) {
            position: initial;
        }
    }
}