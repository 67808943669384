// Gallery block

.block {
    &--gallery {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.gallery {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }
}

.gallery-overview {
    @include clearfix;
    margin: 0 -15px ($default-margin - 30px);

    &__item {
        float: left;
        width: 25%;
        padding: 0 15px;
        margin: 0 0 30px;

        @include media-breakpoint-down(md) {
            width: 50%;
        }

        &:nth-child(3n) {
            float: right;
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        a {
            display: block;
            overflow: hidden;
            border-radius: 6px;

            picture {
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                picture {
                    transform: scale(1.2);
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

.gallery-three {
    @include make-row;
    margin: 0 -15px;

    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }

    .gallery-overview {
        display: flex;
        flex-wrap: wrap;

        &__item {
            @include make-col(3);
            padding: 0 15px;
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin: 0 0 -10px 15px;

            &__item {
                flex: 0 0 68%;
                max-width: 68%;
                padding: 10px;

                img {
                    width: 100%;
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                }
            }
        }

        @include media-breakpoint-up(md) {
            &__item {
                @include make-col(3);
                padding: 0 15px;
            }

            @include media-breakpoint-down(md) {
                &__item {
                    @include make-col(4);
                    padding-left: 25px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .swiper--gallery-slider {
        overflow: initial;

        .swiper-slide {
            height: auto;
        }

        .swiper-navigation {
            margin-top: -30px;
            z-index: 999999;
            position: relative;
        }
    }
}

@include media-breakpoint-up(md) {
    .swiper-navigation {
        display: none !important;
    }
}
