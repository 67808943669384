// Banner block

.block {
    &--banner {
        display: flex;
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.banner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 6px;
    margin: 0 0 $default-margin;

    picture {
        transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus {
        picture {
            transform: scale(1.2);
        }
    }
}
