.header {
    flex-shrink: 0;
    margin: 0 0 30px;
    transition: all .3s ease-in-out;
    z-index: 999;

    &__search {
        background-color: $color-white;
        position: relative;
        display: block;
        width: 100%;
        padding: 15px;
        margin: -78px 0 0;
        transition: all .3s ease-in-out;
        z-index: 10;

        .form-search {
            margin: 0;
            border-radius: 4px;
            overflow: hidden;
        }

        .search-suggestions {
            position: absolute;
            display: none;
            top: 77px;
            left: 15px;
            right: 15px;
            background-color: $color-blue;
            border: 0 solid;
            opacity: .9;
            margin: 0;

            &::after {
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 16px;
                height: 16px;
                content: '';
                transform: rotate(45deg);
                border: 0 solid;
                background-color: $color-blue;
                margin: -8px 0 0 -8px;
            }

            &.active {
                display: block;
            }

            li {
                display: block;
                border-bottom: 1px solid $color-white;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    padding: 10px 15px;
                    font-size: 16px;
                    line-height: 23px;
                    font-weight: 700;
                    color: $color-white;
                }
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 10px 15px;
        background-color: $color-white;
        box-shadow: 0 0 20px transparentize($color-black, .8);
        z-index: 2;

        @include media-breakpoint-up(xl) {
            padding: 10px 30px;
        }

        @include media-breakpoint-down(xl) {
            height: 60px;
            padding: 0;
        }
    }

    &__content-fixed {
        position: fixed;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        padding: 10px 15px;
        background-color: $color-white;
        box-shadow: 0 0 20px transparentize($color-black, .8);
        z-index: 1;

        @include media-breakpoint-up(xl) {
            padding: 10px 30px;
        }

        @include media-breakpoint-down(xl) {
            display: none;
        }

        .navigation {
            &--header {
                order: 1;

                ul {
                    padding: 0;

                    li {
                        a {
                            font-size: 18px;
                            font-weight: $font-weight-bold;

                            @include media-breakpoint-down(xl) {
                                font-size: 14px;
                                font-weight: $font-weight-semibold;
                            }
                        }
                    }
                }

                + .btn {
                    margin: 0 0 0 20px;
                    order: 4;
                }
            }
        }
    }

    .search-toggle {
        @include icon(search, $color-blue-light, 20px, 20px);
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0 0 0 5px;
        font-size: 0;
        transition: all .3s ease-in-out;
        order: 8;

        @include media-breakpoint-down(lg) {
            margin: 0 0 0 10px;
            position: absolute;
            width: 35px;
            height: 35px;
        }

        &:hover,
        &:focus {
            @include icon(search, $color-blue, 20px, 20px);
        }

        &.active {
            @include icon(close, $color-blue-light, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-blue, 20px, 20px);
            }
        }
    }

    .logo {
        display: block;
        height: 40px;
        order: 2;
        margin: 0;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            height: 26px;
            margin: 0 auto;
            margin-top: 20px;

        }

        img {
            display: inherit;
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }

    .navigation {
        &--header {
            flex: 1;
            z-index: 0;
            order: 3;

            @include media-breakpoint-down(xl) {
                flex: unset;
            }
        }
    }

    .cta {
        display: block;
        width: 40px;
        height: 40px;
        padding: 7px;
        margin: 0;
        font-size: 0;
        transition: all .3s ease-in-out;
        z-index: 1;

        &--nav {
            @include icon(menu, $color-blue-light, 20px, 20px);
            display: none;
            order: 1;

            @include media-breakpoint-down(xl) {
                position: absolute;
                top: 50%;
                left: 15px;
                display: block;
                margin: -20px 0 0;
            }

            @include media-breakpoint-down(xl) {
                top: 38px;
            }

            &:hover,
            &:focus {
                @include icon(menu, $text-color, 20px, 20px);
            }

            &:focus-visible {
                outline: 1px solid $color-blue-light;
            }

            &.active {
                @include icon(close, $color-blue-light, 20px, 20px);

                &:hover,
                &:focus {
                    @include icon(close, $color-black, 20px, 20px);
                }
            }
        }
    }

    + .main {
        .block {
            &--hero,
            &--hero-slider {
                margin-top: -30px;
            }
        }
    }
}

.primary-search-active {
    .header {
        &__search {
            margin: 0;
        }
    }
}

html.primary-navigation-active .header {
    &__content {
        @include media-breakpoint-down(sm) {
            height: calc(100vh - 68px);
            overflow: auto;
            align-content: flex-start;
        }
    }
}
