// Hero block

.block {
    &--hero {
        margin: 0 0 $default-block-margin;

        + .block {
            &--breadcrumbs {
                margin-top: -($default-block-margin / 2);
            }

            &--products {
                overflow: inherit;

                @include media-breakpoint-up(lg) {
                    overflow-x: clip;
                    margin-top: -($default-block-margin + 50px);

                    .products {
                        position: relative;

                        &__title {
                            position: absolute;
                            top: -50px;

                            .is-h3 {
                                color: $color-white;
                                text-shadow: 0 0 10px rgba($color-black, .2);
                            }
                        }
                    }
                }

                @include media-breakpoint-down(md) {
                    overflow: hidden;
                }
            }
        }
    }
}

.hero {
    $self: &;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    height: 540px;

    @include media-breakpoint-down(sm) {
        height: 382px;
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        picture {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__video {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        @extend .wrapper;
        z-index: 1;

        .richtext {
            max-width: 866px;
            margin: 0 auto;
            text-align: center;

            .is-h1 {
                color: $color-white;
            }
        }
    }

    &--white {
        #{ $self }__content {
            .is-h1 {
                color: $color-white;
                text-shadow: 0 0 6px $color-black !important; // scss-lint:disable ImportantRule
            }
        }
    }

    &--blue {
        #{ $self }__content {
            .is-h1 {
                color: $color-blue;
            }
        }
    }

    &--blue-light {
        #{ $self }__content {
            .is-h1 {
                color: $color-blue-light;
            }
        }
    }

    &--green {
        #{ $self }__content {
            .is-h1 {
                color: $color-green;
            }
        }
    }

    &--yellow {
        #{ $self }__content {
            .is-h1 {
                color: $color-yellow;
            }
        }
    }

    &--purple {
        #{ $self }__content {
            .is-h1 {
                color: $color-purple;
            }
        }
    }

    &--brown {
        #{ $self }__content {
            .is-h1 {
                color: $color-brown;
            }
        }
    }
}
