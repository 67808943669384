// Breadcrumbs block

.block {
    &--breadcrumbs {
        margin: 0 0 $default-block-margin;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
