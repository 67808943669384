.footer {
    position: relative;
    flex-shrink: 0;

    &__bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 24px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright {
        display: inline-block;
        margin: 0 0 20px;
    }

    .navigation {
        &--footer {
            display: inline-block;
            margin: 0 0 20px;
        }
    }

    .social-share {
        margin: -4px 0 10px auto;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

.footer-doormat {
    $self: &;

    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(2);
        padding: 0 15px;

        @include media-breakpoint-down(xl) {
            width: 18%;
        }

        @include media-breakpoint-down(lg) {
            width: 40%;
        }

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        &:first-of-type {
            @include media-breakpoint-down(lg) {
                width: 60%;
            }

            @include media-breakpoint-down(md) {
                width: 50%;
            }
        }

        &:last-of-type {
            width: 12%;

            @include media-breakpoint-down(xl) {
                width: 100%;
            }
        }

        .is-h5 {
            font-size: 14px;
            line-height: 36px;

            + .list {
                &--footer {
                    margin-top: $default-margin / 2;
                }
            }
        }

        &--large {
            @include make-col(4);

            @include media-breakpoint-down(xl) {
                width: 41%;
            }

            @include media-breakpoint-down(lg) {
                width: 60%;
            }

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            + #{ $self} __item {
                width: 19%;

                @include media-breakpoint-down(xl) {
                    width: 22%;
                }
            }

            .list {
                &--footer {
                    @include clearfix;
                    margin-left: -15px;
                    margin-right: -15px;
                    height: 120px;
                    display: flex;
                    flex-flow: column wrap;
                    column-count: 2;

                    @include media-breakpoint-down(md) {
                        height: auto;
                        column-count: 1;
                    }

                    li {
                        float: left;
                        width: 50%;
                        padding: 0 15px;

                        @include media-breakpoint-down(md) {
                            float: none;
                            clear: both;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
