// Gallery block

.block {
    &--gallery {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.gallery {
    display: flex;
    flex-direction: column;

    &__buttons {
        text-align: center;
    }
}

.gallery-overview {
    @include clearfix;
    margin: 0 -15px ($default-margin - 30px);

    &__item {
        float: left;
        width: 25%;
        padding: 0 15px;
        margin: 0 0 30px;

        @include media-breakpoint-down(md) {
            width: 50%;
        }

        &:nth-child(3n) {
            float: right;
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        a {
            display: block;
            overflow: hidden;
            border-radius: 6px;

            picture {
                transition: all .3s ease-in-out;
            }

            &:hover,
            &:focus {
                picture {
                    transform: scale(1.2);
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

.gallery-three {
    @include make-row;
    margin: 0 -15px;

    .gallery-overview__item {
        @include make-col(3);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(4);
            padding-left: 25px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding-left: 25px;
        }

        img {
            width: 100%;
        }
    }
}
