// Products block

.block {
    &--products {
        position: relative;
        margin: 0 0 $default-block-margin - $default-margin;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            margin: 0 0 ($default-block-margin - $default-margin) - 20px;
        }
    }
}

.products {
    $self: &;
    display: flex;
    flex-direction: column;

    &__content {
        .tns-ovh {
            overflow: visible;
        }
    }
}

.product {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba($color-black, .10);

    &__image {
        overflow: hidden;
        margin: 0 0 $default-margin;

        + #{ $self }__content {
            margin-top: -$default-margin;
        }
    }

    &__content {
        padding: 20px 20px 0;

        .is-h2,
        .is-h3,
        .is-h4,
        .is-h5 {
            color: $color-blue;
            margin: 0 0 $default-margin;
            font-size: 21px;

            + .richtext {
                margin-top: -$default-margin;
            }
        }

        .richtext {
            .btn {
                display: inline;
            }

            + .read-more {
                margin-top: -$default-margin;
            }
        }

        &.event {
            .date {
                margin: -20px 0 5px;
                font-size: 13px;
            }

            .title {
                margin: 0 0 5px;
            }

            .location {
                margin: 0 0 5px;
            }
        }
    }

    .event-link {
        margin: auto 20px 20px;
        display: block;
        text-align: left;
        color: $color-blue;
        font-weight: 500;

        &::after {
            @include icon(arrow, $color-blue, 11px, 11px);
            display: inline-block;
            width: 11px;
            height: 11px;
            content: '';
            transform: rotate(-90deg);
            margin: 0 0 0 7px;
        }
    }

    &[href] {
        color: $text-color;
        text-decoration: none;

        #{ $self }__image {
            img {
                transition: all .3s;
            }
        }

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;

            #{ $self }__image {
                img {
                    transform: scale(1.15);
                }
            }

            #{ $self }__content {
                .is-h2,
                .is-h3,
                .is-h4,
                .is-h5 {
                    color: $color-blue;
                }
            }
        }
    }
}

// scss-lint:disable ImportantRule
.products-slider {
    $self: &;
    display: flex;
    flex-direction: row;
    margin: 0 -10px 40px;

    &__item {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 42%;
            max-width: 42%;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 68%;
            max-width: 68%;
        }

        .product {
            height: 100%;
            margin: 0;
        }
    }
}

// scss-lint:enable ImportantRule

.swiper {
    &--products-slider {
        overflow: initial;

        .swiper-slide {
            height: auto;
        }

        .swiper-navigation {
            margin-top: -30px;
        }
    }
}
